import React, { useEffect } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import styled, { css } from 'styled-components'
import { up } from 'styled-breakpoints'

import MDXRenderer from '@babelcoder/gatsby-theme-base/src/components/mdx/Renderer'
import SEO from '@babelcoder/gatsby-theme-base/src/components/SEO'
import Tabs from '@babelcoder/gatsby-theme-base/src/components/Tabs'
import TabPane from '@babelcoder/gatsby-theme-base/src/components/TabPane'
import useSiteMeta from '@babelcoder/gatsby-theme-base/src/hooks/useSiteMeta'
import toSingleSlash from '@babelcoder/gatsby-theme-base/src/utils/to-single-slash'
import useTipsPath from '../hooks/useTipsPath'

const Container = styled.article`
  max-width: 768px;
  width: 100%;
  margin: 0 auto;
  padding: ${({ theme }) => theme.spacers.large};
`

const Header = styled.header`
  display: grid;
  grid-template-areas:
    'author-image'
    'title'
    'tags'
    'author-name';
  grid-template-rows: max-content;
  grid-template-columns: 1fr;
  justify-items: center;
  column-gap: 1rem;
  margin-bottom: ${({ theme }) => theme.spacers.normal};

  ${up('medium')} {
    grid-template-areas:
      'author-image title title'
      'author-image author-name tags';
    grid-template-rows: max-content;
    grid-template-columns: auto auto 1fr;
    align-items: center;
    justify-items: start;
  }
`

const Image = styled(Img)`
  grid-area: author-image;
  border-radius: 50%;
`

const Title = styled.div`
  ${({ theme }) => css`
    grid-area: title;
    font-size: ${theme.fontSizes.medium};
    color: ${theme.colors.neutral.gray1200};
  `}
`

const AuthorName = styled.div`
  grid-area: author-name;

  ${({ theme }) => css`
    color: ${theme.colors.neutral.gray900};
  `};
`

const Tag = styled.div`
  ${({ theme }) => css`
    grid-area: tags;
    padding: ${theme.spacers.xxsmall} ${theme.spacers.small};
    background-color: ${theme.colors.main.secondary};
    color: ${theme.colors.neutral.white};
    font-size: ${theme.fontSizes.small};
    border-radius: 2rem;
    line-height: 1;
  `}
`

const Excerpt = styled.p`
  color: ${({ theme }) => theme.colors.neutral.gray900};
`

export default function Tip({
  slug,
  title,
  excerpt,
  body,
  image,
  tags,
  createdAtDate,
  updatedAtDate,
}) {
  const { siteUrl } = useSiteMeta()
  const { authorLogo, cover } = useStaticQuery(graphql`
    query {
      authorLogo: imageSharp(original: { src: { regex: "/author/" } }) {
        fixed(width: 65, height: 65) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
      cover: imageSharp(original: { src: { regex: "/tip-cover/" } }) {
        fixed(width: 730, height: 415, quality: 50, webpQuality: 50) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
  `)
  const { getTipPath } = useTipsPath()
  const url = toSingleSlash(`${siteUrl}/${getTipPath(slug)}`)
  const {
    src: coverImageURL,
    width: coverImageWidth,
    height: coverImageHeight,
  } = cover.fixed
  const schema = {
    '@context': 'http://schema.org',
    '@type': 'BlogPosting',
    mainEntityOfPage: {
      '@type': 'WebPage',
      '@id': url,
    },
    inLanguage: 'th-TH',
    name: title,
    image: toSingleSlash(`${siteUrl}${coverImageURL}`),
    headline: excerpt,
    url,
    datePublished: createdAtDate,
    dateModified: updatedAtDate,
  }

  useEffect(() => {
    if(typeof fbq === 'function') {
      // eslint-disable-next-line no-undef
      fbq('track', 'ViewContent', {
        content_type: 'tip',
        content_ids: [slug],
        content_name: title,
      });
    }
  }, [slug, title])

  return (
    <>
      <SEO
        title={title}
        type="article"
        urlPath={url}
        coverImageWidth={coverImageWidth}
        coverImageHeight={coverImageHeight}
        coverImageURL={coverImageURL}
        desc={excerpt}
        schema={schema}
      ></SEO>
      <Container>
        <Header>
          <Image fixed={authorLogo.fixed}></Image>
          <Title>{title}</Title>
          <AuthorName>Nuttavut Thongjor</AuthorName>
          <Tag>{tags}</Tag>
        </Header>
        <Img fluid={image.childImageSharp.fluid}></Img>
        <Excerpt>{excerpt}</Excerpt>
        <Tabs>
          <TabPane label="คำอธิบาย" index={0}>
            <MDXRenderer>{body}</MDXRenderer>
          </TabPane>
          <TabPane label="ความคิดเห็น" index={1}></TabPane>
        </Tabs>
      </Container>
    </>
  )
}
