import React from 'react'
import { graphql } from 'gatsby'

import Tip from '../components/Tip'

export default function TipTemplate({ data: { mdx } }) {
  const {
    frontmatter,
    body,
    fields: { slug },
  } = mdx

  return <Tip {...frontmatter} slug={slug} body={body}></Tip>
}

export const pageQuery = graphql`
  query TipQuery($slug: String!) {
    mdx(fields: { slug: { eq: $slug } }) {
      body
      tableOfContents
      frontmatter {
        title
        excerpt
        tags
        createdAt(formatString: "DD MMM YYYY", locale: "th-TH")
        createdAtDate: createdAt
        updatedAtDate: updatedAt
        includesMath
        image {
          childImageSharp {
            fluid(maxWidth: 768, quality: 50, webpQuality: 50) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
      fields {
        slug
      }
    }
  }
`
